/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 71번째 로그에서는 팟캐스트 녹음 환경, 아마존닷컴 반품 경험, gRPC 로드 밸런싱 대한 이야기를 나눴습니다."), "\n", React.createElement(_components.h2, null, "녹화 환경 소개"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sony.com/electronics/interchangeable-lens-cameras/ilce-6400"
  }, "α6400 E-mount camera with APS-C Sensor | ILCE-6400 / ILCE-6400L / ILCE-6400M | Sony US")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sony.com/electronics/camera-lenses/sel1635gm"
  }, "FE 16–35 mm G Master Wide-Angle Zoom Lens | SEL1635GM | Sony US")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sony.com/electronics/cyber-shot-compact-cameras/dsc-rx100m3-dsc-rx100m3g"
  }, "DSC-RX100 III Compact Digital Camera | Cyber-shot Pocket Camera | Sony US")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sony.com/electronics/handycam-camcorders/fdr-ax700"
  }, "4K HDR Camcorder with Fast Hybrid AF | 4K Handycam FDR-AX700 | Sony US")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sony.jp/handycam/products/FDR-AX60/"
  }, "FDR-AX60 | デジタルビデオカメラ Handycam ハンディカム | ソニー")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=3cqy-nKcAnY"
  }, "홍진경 더 만두 리뷰를 빙자한 a6400 + SEL1635FE + MixPre-3 II + Shure SM57 - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.dpreview.com/reviews/good-genes-samsung-nx500-review-posted"
  }, "Good genes: Samsung NX500 review posted: Digital Photography Review")), "\n"), "\n", React.createElement(_components.h2, null, "인사이트 번역가 모집"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/insightbook/photos/a.274147892742973/1846294892194924/?type=3&__tn__=-R"
  }, "도서출판 인사이트 - BPF Performance Tools: Linux System and Application Observability 역자 모집(마감)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.yes24.com/Product/Goods/23314903"
  }, "시스템 성능 분석과 최적화 - YES24")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/DTrace"
  }, "DTrace - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Berkeley_Packet_Filter"
  }, "Berkeley Packet Filter - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.brendangregg.com/"
  }, "Brendan Gregg's Homepage")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/insightbook/posts/1846471002177313?__xts__%5B0%5D=68.ARCkYcmeH6ffZXg7DEvXy-waAEThZ_kWMHSkinzmW-74o3-f8oHnfj-btil2DXkizvlAhBaUnZgQDcyDNe2urEORcF9Y22-0yU_YmB0W1PsWw8cffnHhGbzNyId3qGVySg5l8wN-XMuf0iJiRGGH_Ts4kW7C5iP3CRAXmys2XUSuJGG7fa2jajmZV3wgM7kOYggGmEIg21eteJHGIPIDzt8jPdL6Jz5Iqgd3E9AD9vQBbDbefV4cgDQnf1F7zcjGmJs4EqBnbi8xrlA6KHDw6Ln-xca3-RyB9bucW7q_avlmZ6Yf91cNZBSIaIrTmrsSk3bIHlOkMug-12OmhI2Os6JvWg&__tn__=-R"
  }, "도서출판 인사이트 - Rust in Action 역자 모집(마감)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=202322454"
  }, "알라딘: 클린 아키텍처")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=34083680"
  }, "알라딘: 클린 코드 Clean Code")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.yes24.com/Product/Goods/84937604"
  }, "기계는 어떻게 생각하는가? - YES24")), "\n"), "\n", React.createElement(_components.h2, null, "ODK는 구인중"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.odkmedia.net/#careers"
  }, "ODK Media")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hackerrank.com/"
  }, "HackerRank")), "\n"), "\n", React.createElement(_components.h2, null, "유튜브 익스플로어 지원 중단"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.hani.co.kr/arti/economy/it/927517.html"
  }, "3월부터 인터넷 익스플로러로 유튜브 못 본다 : IT : 경제 : 뉴스 : 한겨레")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/WebP"
  }, "WebP - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/VP9"
  }, "VP9 - Wikipedia")), "\n"), "\n", React.createElement(_components.h2, null, "오라클 신한은행 라이센스 분쟁"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.clien.net/service/board/news/14532632"
  }, "오라클 “신한은행, 수백억 내놔라” : 클리앙")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/outposts/"
  }, "AWS Outposts 개요 페이지")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/rds/aurora/"
  }, "데이터 베이스 관리 시스템 | MySQL | Amazon Web Services")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/rds/aurora/serverless/"
  }, "Amazon Aurora 서버리스 - 온디맨드 Auto-scaling 관계형 데이터베이스 - AWS")), "\n"), "\n", React.createElement(_components.h2, null, "판교 낙생지구"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://realty.chosun.com/site/data/html_dir/2020/01/23/2020012302722.html"
  }, "남판교에 1만가구 주거타운 뜬다…대장동 이어 낙생지구 개발 - 땅집고 > 투자리포트")), "\n"), "\n", React.createElement(_components.h2, null, "아마존 반품 이야기"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.kickstarter.com/projects/215201435/dart-the-worlds-smallest-laptop-adapter?lang=ja"
  }, "Dart: The World's Smallest Laptop Adapter by FINsix — Kickstarter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Wireless_intrusion_prevention_system"
  }, "Wireless intrusion prevention system - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hankyung.com/it/article/2010012224241"
  }, "속도내는 스마트폰 열풍 구글 넥서스원 국내 첫 개통자나와 | 한경닷컴")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://biz.chosun.com/site/data/html_dir/2010/04/26/2010042602322.html"
  }, "아이패드 사용 불법이라더니..장관은 예외? - Chosunbiz > 테크 > ICT/미디어")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.joins.com/article/23119097"
  }, "해외 직구 되팔기 '불법'이라는 정부… 직구족들 \"현실과 괴리\" 불만 - 중앙일보")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://keychron.kr/"
  }, "키크론 Keychron – 맥도 윈도우도 문제없다.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.bizwatch.co.kr/article/tax/2016/07/21/0032"
  }, "신고 안한 샤넬백, 공항서 걸릴 확률은?")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bose.com/en_us/products/headphones/noise_cancelling_headphones/noise-cancelling-headphones-700.html#v=noise_cancelling_headphones_700_soapstone"
  }, "Smart Noise Cancelling Headphones 700 | Bose")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.alibaba.com/"
  }, "Alibaba.com: Manufacturers, Suppliers, Exporters & Importers from the world's largest online B2B marketplace")), "\n"), "\n", React.createElement(_components.h2, null, "gRPC 로드밸런싱"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://grpc.io/blog/loadbalancing/"
  }, "gRPC")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/elasticloadbalancing/"
  }, "웹 서버 로드 밸런싱 | 서버 로드 밸런싱 | Amazon Web Services")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.envoyproxy.io/"
  }, "Envoy Proxy - Home")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/cloud-map/"
  }, "Cloud Map – 클라우드 리소스를 위한 서비스 검색")), "\n"), "\n", React.createElement(_components.h2, null, "AWS 라이트세일"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/about-aws/whats-new/2018/11/amazon-lightsail-now-provides-an-upgrade-path-to-ec2/"
  }, "Amazon Lightsail, 이제 EC2 업그레이드 경로 제공")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.digitalocean.com/"
  }, "DigitalOcean – The developer cloud")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ec2instances.info/"
  }, "Amazon EC2 Instance Comparison")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/batch/"
  }, "AWS Batch – 쉽고 효율적인 배치 컴퓨팅 기능 – AWS")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
